import { createSlice } from "@reduxjs/toolkit";

const ZoneSlice = createSlice({
  name: "Zone",
  initialState: {
    zoneDetails: "",
  },
  reducers: {
    setzoneDetails: (state, action) => {
      state.zoneDetails = action.payload;
    },
  },
});

export const { setzoneDetails } = ZoneSlice.actions;
export default ZoneSlice.reducer;
