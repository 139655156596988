import { createSlice } from "@reduxjs/toolkit";

const ScreenSizeSlice = createSlice({
  name: "ScreenSize",
  initialState: {
    screensizeDetails: "",
  },
  reducers: {
    setscreensizeDetails: (state, action) => {
      state.screensizeDetails = action.payload;
    },
  },
});

export const { setscreensizeDetails } = ScreenSizeSlice.actions;
export default ScreenSizeSlice.reducer;
