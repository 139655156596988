import { configureStore } from "@reduxjs/toolkit";
import LoaderReducer from "../redux/slices/LoaderSlice";
import ZoneReducer from "../redux/slices/ZoneSlice";
import ScreenSizeReducer from "../redux/slices/ScreenSizeSlice";
export const store = configureStore({
  reducer: {
    Loader: LoaderReducer,
    Zone: ZoneReducer,
    ScreenSize: ScreenSizeReducer,
  },
});
