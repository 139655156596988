import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// routes
// import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/common.css";
import ScrollToTop from "./components/scroll-to-top";
import { lazy, Suspense, useEffect, useState } from "react";
import Loader from "./utils/CommonLoader/Loader";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

// ----------------------------------------------------------------------
const Router = lazy(() => import("./routes"));

export default function App() {
  const LoaderView = useSelector((state) => state?.Loader.View);

  const accessToken = sessionStorage.getItem("Token");

  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") || ""
  );

  useEffect(() => {
    if (!sessionStorage.getItem("isAuthenticated")) {
      sessionStorage.setItem("isAuthenticated", isAuthenticated);
    }
  }, [isAuthenticated]);
  const handleLogin = (data) => {
    setIsAuthenticated(data);
    sessionStorage.setItem("isAuthenticated", data);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.clear();
  };
  return (
    <>
      {LoaderView ? <Loader /> : ""}

      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <Suspense fallback={<Loader />}>
              <StyledChart />
              <Router
                isAuthenticated={isAuthenticated}
                onLogin={handleLogin}
                onLogout={handleLogout}
              />
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}
